.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #FF0200 !important;
    background-color: transparent !important;
    border: transparent !important;
    border-bottom: 1px solid #FF0200 !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
    color: #1B2A40 !important;
    font-size: 13px !important;
    font-weight: 500 !important;
}

.nav-tabs .nav-item:last-child .nav-link {
    border-right: none !important;
}

.badg {
    background-color: #eff2f7 !important;
    padding: 8px !important;
    font-size: 10px !important;
    font-weight: 500 !important;
}