.custom-primary {
    background-color: #09b294 !important;
    border-color: #09b294;
    color: #09b294 !; 
  }
  
  .custom-primary:hover {
    background-color: #09b294;;
    border-color: #09b294;
    color: black !important; /* Change the text color on hover */
  }

  .custom-close {
    background-color: red !important;
    border-color: red;
    color: white !; 
  }
  
  .custom-close:hover {
    background-color: red;;
    border-color: red;
    color: black !important; /* Change the text color on hover */
  }
  .modal-footer button {
    text-transform: none;
  }